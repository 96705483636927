import styled from '@emotion/styled'

import * as styles from 'src/styles'
import * as breakpoints from 'src/styles/mediaQuery'

export const NewHeroCardContainer = styled.div(({theme}) => ({
  borderRadius: 19,
  backgroundColor: theme.colors.white,
  padding: '17px 20px 50px 36px',
  ...styles.shadow.homeHeroCardShadow,
  // width: '100%',
  maxWidth: '290px',
  margin: '0 auto',
  marginTop: '-5.5rem',
  // left: '50%',
  // transform: 'translateX(-50%)',
  position: 'relative',
  width: styles.cssCalc('100%', '-', '4rem'),
  [breakpoints.large]: {
    top: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.space[1],
    transform: 'none',
    maxWidth: '375px',
    ...styles.shadow.noShadow,
  },
}))
