import {graphql} from 'gatsby'

import {SmallBody, Body, Lede} from 'src/components/text'
import {Box, Flex} from 'src/components/Box'
import {ActionLink} from 'src/components/Action'
import {ArrowIconToRight, CardsIcon} from 'src/components/icons'
import Card, {CardProps} from 'src/components/Card'
import {Maybe} from 'src/graphql-generated'
import FlairBox from 'src/components/FlairBox'
import {pluralize} from 'src/lib/util'

interface Props {
  heroUrl?: Maybe<CardProps['backgroundImage']>
  title: string
  description?: Maybe<string>
  articleCount: number
  link: string
  truncateDescription?: boolean
}

export const query = graphql`
  fragment MilestoneCard on ContentfulMilestone {
    __typename
    id
    description {
      description
    }
    permalink
    articleCount
    name
    heroRemoteUrl
    heroFile {
      ...CardBackgroundImage
    }
  }
`

const ContentCard: React.FunctionComponent<
  Pick<Props, 'title' | 'description' | 'articleCount' | 'truncateDescription'>
> = ({title, description, articleCount, truncateDescription}) => (
  <Card>
    <FlairBox right={-16} top="10px" height={100} width={67} flairType="slim" />
    <Flex
      flexDirection="column"
      flex="1"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Box>
        <Flex alignItems="center" mb="3">
          <CardsIcon color="grey5" mr="1" />
          <SmallBody color="grey5" fontWeight="2">
            {articleCount} {pluralize('item', articleCount)}
          </SmallBody>
        </Flex>
        <Lede mb="1" fontWeight="2">
          {title}
        </Lede>
        {description && (
          <SmallBody mb="3" lines={truncateDescription ? 2 : undefined}>
            {description}
          </SmallBody>
        )}
      </Box>

      <Flex alignItems="center">
        <Body fontWeight="2">Check out this series</Body>&nbsp;
        <ArrowIconToRight ml="2" />
      </Flex>
    </Flex>
  </Card>
)

const MilestoneCard = ({
  heroUrl,
  title,
  description,
  articleCount,
  link,
  truncateDescription = false,
}: Props) => {
  if (!heroUrl) {
    return (
      <ActionLink data-cy="journey-milestone" to={link}>
        <ContentCard
          title={title}
          description={description}
          articleCount={articleCount}
          truncateDescription={truncateDescription}
        />
      </ActionLink>
    )
  }
  return (
    <ActionLink data-cy="journey-milestone" to={link}>
      <Flex flexDirection="column" height="100%">
        <Card backgroundImage={heroUrl}>
          <Box height={{_: '150px', md: '160px', lg: '10rem'}} />
        </Card>
        <Box mt="-50px" height="100%">
          <ContentCard
            title={title}
            description={description}
            articleCount={articleCount}
            truncateDescription={truncateDescription}
          />
        </Box>
      </Flex>
    </ActionLink>
  )
}

export default MilestoneCard
