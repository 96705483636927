import {Header2} from 'src/components/text'
import HomeFlairBox from 'src/components/HomePage/HomeFlairBox'
import {Grid, Flex} from 'src/components/Box'
import {FeaturedSectionIcon} from 'src/components/icons'
import useHomeFeatures from 'src/hooks/useHomeFeatures'
import HeroFeaturedCard from 'src/components/HeroFeaturedCard'
import FeaturedCard from 'src/components/FeaturedCard'
import * as breakpoints from 'src/styles/mediaQuery'
import {UNDERLINE_MAP, Underline} from 'src/components/ResourceUnderline'
import {FlairProvider} from 'src/context/FlairContext'

const FeaturedSection = () => {
  const homeFeatures = useHomeFeatures()

  if (!homeFeatures || ![1, 4].includes(homeFeatures.length)) {
    return <Flex position="relative" marginTop={{_: '0'}} pt={0} />
  }

  const primaryTile = homeFeatures[0]
  const secondaryTiles = homeFeatures.slice(1)

  return (
    <Flex
      position="relative"
      justifyContent={{lg: 'center'}}
      marginTop={{_: '15px', lg: 0}}
    >
      <HomeFlairBox
        display={'none'}
        name="xx"
        left={{_: -50, lg: 0}}
        top={{_: -100, lg: -50}}
        height={{_: 200, lg: 125}}
        width={{_: 200, lg: 125}}
        css={{
          zIndex: -1,
          [breakpoints.large]: {
            zIndex: 1,
          },
        }}
      />
      <Grid position="relative" pt={{_: 0, lg: 4}} pb="9" gridGap="9" flex="1">
        <FlairProvider seed={Number(primaryTile.id)}>
          <Grid gridGap="3">
            <Flex alignItems="center" mb={1}>
              <FeaturedSectionIcon mr="1" height="2rem" width="2rem" />
              <Underline source={UNDERLINE_MAP[0]}>
                <Header2 truncate={false}>Featured</Header2>
              </Underline>
            </Flex>

            <HeroFeaturedCard
              heroUrl={
                primaryTile.heroFile?.childImageSharp?.fluid ||
                primaryTile.heroRemoteUrl
              }
              link={primaryTile.permalink}
              summary={primaryTile.summary?.summary}
              title={primaryTile.title}
              truncateDescription
            />

            <Grid
              gridGap="3"
              gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
            >
              {secondaryTiles.map((t) => (
                <FeaturedCard
                  key={t.id}
                  heroUrl={
                    t.heroFile?.childImageSharp?.fluid || t.heroRemoteUrl
                  }
                  link={t.permalink}
                  summary={t.summary?.summary}
                  title={t.title}
                  truncateDescription
                />
              ))}
            </Grid>
          </Grid>
        </FlairProvider>
      </Grid>
    </Flex>
  )
}

export default FeaturedSection
