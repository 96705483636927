import take from 'lodash/take'

import {Header2, Lede} from 'src/components/text'
import HomeFlairBox from 'src/components/HomePage/HomeFlairBox'
import {Grid, Flex} from 'src/components/Box'
import useCoreJourneys from 'src/hooks/useCoreJourneys'
import JourneyIcon from 'src/components/JourneyIcon'
import {ActionLink} from 'src/components/Action'
import MilestoneCard from 'src/components/Journey/MilestoneCard'
import {ArrowIconToRight} from 'src/components/icons'
import {UNDERLINE_MAP, Underline} from 'src/components/ResourceUnderline'
import {FlairProvider} from 'src/context/FlairContext'
import * as breakpoints from 'src/styles/mediaQuery'

const MILESTONE_SAMPLE_SIZE = 3 as const

const JourneySection = () => {
  const coreJourneys = useCoreJourneys()

  return (
    <Grid position="relative" py="9" gridGap="9">
      <HomeFlairBox
        name="circleHamburger"
        right={0}
        top={-130}
        height={200}
        width={200}
        css={{
          display: 'none',
          [breakpoints.medium]: {
            display: 'block',
          },
        }}
      />
      {coreJourneys.map((journey, index) => {
        const milestoneSample = take(journey.milestones, MILESTONE_SAMPLE_SIZE)

        const underlineSource = UNDERLINE_MAP[index % UNDERLINE_MAP.length]
        return (
          <FlairProvider key={journey.id} seed={Number(journey.id)}>
            <Grid key={journey.id} gridGap="3">
              <ActionLink to={journey.permalink} css={{height: '100%'}}>
                <Flex alignItems="center">
                  {journey.icon && (
                    <JourneyIcon
                      icon={journey.icon}
                      mr="1"
                      height="2rem"
                      width="2rem"
                    />
                  )}
                  <Underline source={underlineSource}>
                    <Header2>{journey.name}</Header2>
                  </Underline>
                  <ArrowIconToRight ml="1" />
                </Flex>
              </ActionLink>

              {journey.shortDescription && (
                <Lede>{journey.shortDescription.shortDescription}</Lede>
              )}

              <Grid
                gridGap="3"
                gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
              >
                {milestoneSample.map((m) => (
                  <MilestoneCard
                    key={m.id}
                    heroUrl={
                      m.heroFile?.childImageSharp?.fluid || m.heroRemoteUrl
                    }
                    articleCount={m.articleCount}
                    link={m.permalink}
                    description={m.description?.description}
                    title={m.name}
                    truncateDescription
                  />
                ))}
              </Grid>
            </Grid>
          </FlairProvider>
        )
      })}
    </Grid>
  )
}

export default JourneySection
