/* eslint-disable @typescript-eslint/no-non-null-assertion*/
import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {GatsbyImage, getImage, withArtDirection} from 'gatsby-plugin-image'

import * as breakpoints from 'src/styles/mediaQuery'
import {theme} from 'src/styles/theme'

const query = graphql`
  query NewHomeHeroImage {
    smallMobileImage: file(relativePath: {eq: "newhero_sm.png"}) {
      childImageSharp {
        gatsbyImageData(width: 768, quality: 100, layout: CONSTRAINED)
      }
    }
    mobileImage: file(relativePath: {eq: "newhero_md.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    desktopImage: file(relativePath: {eq: "newhero_superwide.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const NewHomeHeroImage = () => {
  const data = useStaticQuery(query)

  const images = withArtDirection(getImage(data.smallMobileImage)!, [
    {
      image: getImage(data.desktopImage)!,
      media: `(min-width: ${theme.breakpoints[2]})`,
    },
    {
      image: getImage(data.mobileImage)!,
      media: `(min-width: 580px)`,
    },
  ])

  return (
    <GatsbyImage
      image={images}
      alt="GetSchooled Home"
      css={{
        // left: '50%',
        // transform: 'translate(-50%, 0)',
        width: '100%',
        // maxWidth: '312px',
        margin: '0 auto',
        position: 'relative',
        top: '-20px',
        // marginTop: '-20px',
        [breakpoints.medium]: {
          maxWidth: '425px',
        },
        [breakpoints.large]: {
          // maxWidth: '425px',
        },
      }}
    />
  )
}

export default NewHomeHeroImage
