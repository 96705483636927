import {SmallBody, Lede} from 'src/components/text'
import {Box, Flex} from 'src/components/Box'
import {ActionLink} from 'src/components/Action'
import {FeaturedCardCta} from 'src/components/FeaturedCardCta'
import {FeaturedIcon} from 'src/components/icons'
import Card, {CardProps} from 'src/components/Card'
import useNoImageBackground from 'src/hooks/useNoImageBackground'
import {Maybe} from 'src/graphql-generated'
import {theme} from 'src/styles'

interface Props {
  heroUrl?: Maybe<CardProps['backgroundImage']>
  title: string
  summary?: Maybe<string>
  link: string
  showCta?: boolean
  truncateDescription?: boolean
}

type ContentCardProps = Pick<
  Props,
  'title' | 'summary' | 'truncateDescription' | 'showCta'
>

const ContentCard = ({
  title,
  summary,
  showCta,
  truncateDescription,
}: ContentCardProps) => (
  <Card>
    <Flex
      flexDirection="column"
      flex="1"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Box>
        <Flex alignItems="center" mb="3">
          <FeaturedIcon mr="1" />
          <SmallBody color="green" fontWeight="2">
            Featured
          </SmallBody>
        </Flex>
        <Lede mb="1" fontWeight="2">
          {title}
        </Lede>
        {summary && (
          <SmallBody lines={truncateDescription ? 3 : undefined}>
            {summary}
          </SmallBody>
        )}
      </Box>

      {showCta && (
        <Box mt={3}>
          <FeaturedCardCta />
        </Box>
      )}
    </Flex>
  </Card>
)

const FeaturedCard = ({
  heroUrl,
  title,
  summary,
  link,
  showCta = false,
  truncateDescription = false,
}: Props) => {
  const noImageBackground = useNoImageBackground()

  return (
    <ActionLink data-cy="journey-milestone" to={link} theme={theme}>
      <Flex flexDirection="column" height="100%">
        <Card backgroundImage={heroUrl === null ? noImageBackground : heroUrl}>
          <Box height={{_: '150px', md: '160px', lg: '10rem'}} />
        </Card>
        <Box mt="-50px" height="100%">
          <ContentCard
            title={title}
            summary={summary}
            truncateDescription={truncateDescription}
            showCta={showCta}
          />
        </Box>
      </Flex>
    </ActionLink>
  )
}

export default FeaturedCard
