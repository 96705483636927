import {useStaticQuery, graphql} from 'gatsby'
import {HomeFeaturesQuery} from 'gatsby-graphql-types-generated'

const useHomeFeatures = () => {
  const {
    contentfulFeaturedTiles: {featuredTiles},
  } = useStaticQuery<HomeFeaturesQuery>(graphql`
    fragment FeaturedTile on ContentfulArticleTile {
      id
      title
      summary {
        summary
      }
      permalink
      heroRemoteUrl
      heroFile {
        ...CardBackgroundImage
      }
    }

    fragment FeaturedResource on ContentfulResourcePage {
      id
      title
      summary {
        summary
      }
      permalink
      heroRemoteUrl
      heroFile {
        ...CardBackgroundImage
      }
    }

    query HomeFeatures {
      contentfulFeaturedTiles(slug: {eq: "landing"}) {
        featuredTiles {
          ... on ContentfulArticleTile {
            ...FeaturedTile
          }
          ... on ContentfulResourcePage {
            ...FeaturedResource
          }
        }
      }
    }
  `)

  return featuredTiles
}

export default useHomeFeatures
