import HomeFlair1 from 'assets/svgs/home-flair/flair_home-1.svg'
import HomeFlair2 from 'assets/svgs/home-flair/flair_home-2.svg'
import HomeFlair3 from 'assets/svgs/home-flair/flair_home-3.svg'
import HomeFlair4 from 'assets/svgs/home-flair/flair_home-4.svg'
import HomeFlair5a from 'assets/svgs/home-flair/flair_home-5a.svg'
import HomeFlair5b from 'assets/svgs/home-flair/flair_home-5b.svg'
import HomeFlair6a from 'assets/svgs/home-flair/flair_home-6a.svg'
import HomeFlair6b from 'assets/svgs/home-flair/flair_home-6b.svg'

import BackgroundImage, {
  Props as BackgroundImageProps,
} from 'src/components/BackgroundImage'

const HomeFlair = {
  xx: HomeFlair1,
  triangleSquare: HomeFlair2,
  chevronCircle: HomeFlair3,
  barsX: HomeFlair4,
  triangle: HomeFlair5a,
  chevron: HomeFlair5b,
  hamburgerChevron: HomeFlair6a,
  circleHamburger: HomeFlair6b,
}

interface Props extends Omit<BackgroundImageProps, 'url'> {
  name: keyof typeof HomeFlair
}

const HomeFlairBox = ({name, zIndex = -1, ...props}: Props) => {
  return (
    <BackgroundImage
      url={HomeFlair[name]}
      position="absolute"
      zIndex={zIndex}
      {...props}
    />
  )
}

export default HomeFlairBox
