import {SmallBody, Body, Lede} from 'src/components/text'
import {Box, Flex, Grid} from 'src/components/Box'
import {ActionLink} from 'src/components/Action'
import FeaturedCard from 'src/components/FeaturedCard'
import {ArrowIconToRight, FeaturedIcon} from 'src/components/icons'
import Card, {CardProps} from 'src/components/Card'
import * as breakpoints from 'src/styles/mediaQuery'
import useNoImageBackground from 'src/hooks/useNoImageBackground'
import {Maybe} from 'src/graphql-generated'
import {theme} from 'src/styles'

interface Props {
  heroUrl?: Maybe<CardProps['backgroundImage']>
  title: string
  summary?: Maybe<string>
  link: string
  truncateDescription?: boolean
}

const Cta = () => (
  <Flex alignItems="center">
    <Body color="green" fontWeight="2">
      Check it out
    </Body>
    &nbsp;
    <ArrowIconToRight color="green" ml="2" />
  </Flex>
)

type ContentCardProps = Pick<Props, 'title' | 'summary' | 'truncateDescription'>

const ContentCard = ({
  title,
  summary,
  truncateDescription,
}: ContentCardProps) => (
  <Card>
    <Flex
      flexDirection="column"
      flex="1"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Box>
        <Flex alignItems="center" mb="3">
          <FeaturedIcon mr="1" />
          <SmallBody color="green" fontWeight="2">
            Featured
          </SmallBody>
        </Flex>
        <Lede mb="1" fontWeight="2">
          {title}
        </Lede>
        {summary && (
          <SmallBody mb="4" lines={truncateDescription ? 4 : undefined}>
            {summary}
          </SmallBody>
        )}
      </Box>

      <Box mt={3}>
        <Cta />
      </Box>
    </Flex>
  </Card>
)

const HeroFeaturedCard = ({
  heroUrl,
  title,
  summary,
  link,
  truncateDescription = false,
}: Props) => {
  const noImageBackground = useNoImageBackground()

  return (
    <Box width="100%">
      <ActionLink
        data-cy="journey-milestone"
        to={link}
        theme={theme}
        css={{
          display: 'none',
          [breakpoints.medium]: {
            display: 'block',
          },
        }}
      >
        <Grid
          gridGap="3"
          gridTemplateColumns={{
            _: '1fr',
            md: '1fr 1fr',
            lg: '1fr 2fr',
          }}
        >
          <Box zIndex={20}>
            <ContentCard
              title={title}
              summary={summary}
              truncateDescription={truncateDescription}
            />
          </Box>
          <Box ml={-60} zIndex={10}>
            <Card
              backgroundImage={heroUrl === null ? noImageBackground : heroUrl}
            >
              <Box height={{_: '150px', md: '160px', lg: '10rem'}} />
            </Card>
          </Box>
        </Grid>
      </ActionLink>
      <Grid
        gridGap="3"
        gridTemplateColumns="1fr"
        css={{
          [breakpoints.medium]: {
            display: 'none',
          },
        }}
      >
        <FeaturedCard
          heroUrl={heroUrl}
          link={link}
          summary={summary}
          title={title}
          truncateDescription={truncateDescription}
          showCta
        />
      </Grid>
    </Box>
  )
}

export default HeroFeaturedCard
