import {useFlairContext, FlairSampler} from 'src/context/FlairContext'

import BackgroundImage, {Props as BackgroundImageProps} from './BackgroundImage'

interface Props extends Omit<BackgroundImageProps, 'url'> {
  flairType?: keyof FlairSampler
  flairCacheKey?: string
  children?: React.ReactNode | React.ReactNode[]
}

const FlairBox = ({
  flairCacheKey,
  children,
  flairType = 'flair',
  position = 'absolute',
  zIndex = -1,
  ...props
}: Props) => {
  const bundle = useFlairContext()
  const flairUrl = bundle[flairType](flairCacheKey)
  if (!flairUrl) {
    return null
  }
  return (
    <BackgroundImage
      url={flairUrl}
      position={position}
      zIndex={zIndex}
      {...props}
    >
      {children}
    </BackgroundImage>
  )
}

export default FlairBox
