import styled from '@emotion/styled'

const ColorWall = styled.div({
  left: '50%',
  transform: 'translate(-50%, 0)',
  position: 'absolute',
  zIndex: -2,
  top: 0,
  bottom: 0,
  width: '100vw',
})

export default ColorWall
