import {graphql, useStaticQuery} from 'gatsby'

const query = graphql`
  query NoImageBackground {
    image: file(relativePath: {eq: "noimage_pattern.png"}) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useNoImageBackground = () =>
  useStaticQuery(query).image.childImageSharp.fluid

export default useNoImageBackground
