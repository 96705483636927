import {useEffect, useRef} from 'react'
import {navigate} from 'gatsby'

import useViewer from 'src/hooks/useViewer'

export const useRedirectIfAuthenticated = (path: string) => {
  const {loading, loggedIn} = useViewer()
  const handled = useRef(false)

  useEffect(() => {
    if (loading || handled.current) {
      return
    }

    if (!handled.current && loggedIn) {
      navigate(path)
    }

    handled.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return loading || loggedIn
}
