import {Body} from 'src/components/text'
import {Flex} from 'src/components/Box'
import {ArrowIconToRight} from 'src/components/icons'

export const FeaturedCardCta = () => (
  <Flex alignItems="center">
    <Body color="green" fontWeight="2">
      Check it out
    </Body>
    &nbsp;
    <ArrowIconToRight color="green" ml="2" />
  </Flex>
)
